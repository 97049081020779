.curve-divider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.curve-divider svg {
  position: relative;
  display: block;
  width: calc(144% + 1.3px);
}

.swiper-pagination-bullet-active {
  background: #1faa6c !important;
}

.swiper-button-next, .swiper-button-prev {
  --swiper-navigation-color: transparent;
  --swiper-navigation-size: 25px;
}

.swiper-button-disabled {
  --swiper-navigation-color: transparent !important;
}

@media only screen and (min-width: 640px) {
  .swiper-button-next, .swiper-button-prev {
    --swiper-navigation-color: rgb(31, 41, 5);
  }
}

.swiper-button-prev {
  left: 15px !important;
}

.swiper-button-next {
  right: 15px !important;
}

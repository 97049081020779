.desert-divider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.desert-divider svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
}

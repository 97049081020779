.diagonal-separator {
  position: relative;
  z-index: 10;
  transform: skewY(-3deg);
  transform-origin: top left;
}

.diagonal-separator .inverted {
  transform: skewY(3deg);
}

.hero-header {
  transform: scale(1.5);
}

@keyframes fadeInSlideDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.animated-tour {
    opacity: 0;
    animation-fill-mode: forwards;
    animation-name: fadeInSlideDown;
}

.tour-1 {
    animation-duration: 1s;
    animation-delay: 0.5s;
}

.tour-2 {
    animation-duration: 1.2s;
    animation-delay: 1s;
}

.tour-3 {
    animation-duration: 1.4s;
    animation-delay: 1.5s;
}
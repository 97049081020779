@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wa-chat-bubble-wrapper p:first-child {
  color: #075f55 !important;
  font-family: "Inter", "Noto Sans TC", "Noto Sans SC", "Helvetica", "Arial", sans-serif !important;
  font-weight: 700 !important;
  margin-bottom: 0 !important;
}

.wa-powered-by-label {
  display: none;
}

.wa-cta-button {
  background-color: #075f55 !important;
  -webkit-box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.32);
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.32);
  transition: transform ease 300ms;
}

.wa-cta-button:hover {
  opacity: 100% !important;
  transform: translate(0, -5px);
}

.start-chat-section {
  padding-bottom: -20px !important;
}

#wa-close-btn {
  transition: transform ease 200ms;
}

#wa-close-btn:hover {
  transform: scale(1.2)
}

.wa-widget-brand-image {
  border: solid white 2px;
}
